import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconAppStore = ({ className }: { className?: string }) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      className={className}
    >
      <path
        d="M18.0397 14.1328C18.0654 12.2029 19.1355 10.3768 20.833 9.366C19.7621 7.88464 17.9684 6.94542 16.1022 6.88886C14.1117 6.68651 12.1821 8.04247 11.1678 8.04247C10.1338 8.04247 8.57216 6.90895 6.8905 6.94247C4.69852 7.01106 2.65504 8.21811 1.5886 10.0742C-0.703832 13.9184 1.00612 19.568 3.20207 22.6754C4.30076 24.197 5.58481 25.8966 7.26495 25.8363C8.90908 25.7703 9.52313 24.8209 11.5077 24.8209C13.4739 24.8209 14.05 25.8363 15.7643 25.798C17.5285 25.7703 18.64 24.2697 19.7002 22.7337C20.4896 21.6495 21.097 20.4513 21.5 19.1833C19.4267 18.334 18.0422 16.3131 18.0397 14.1328Z"
        fill="#FAF8FF"
      />
      <path
        d="M14.8018 4.84532C15.7637 3.72688 16.2376 2.2893 16.1229 0.837891C14.6533 0.987391 13.2958 1.66768 12.3208 2.74322C11.3675 3.79407 10.8714 5.20635 10.9652 6.60229C12.4353 6.61695 13.8806 5.9551 14.8018 4.84532Z"
        fill="#FAF8FF"
      />
    </svg>
  )
}
