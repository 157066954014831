import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconGGPlay = ({ className }: { className?: string }) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      className={className}
    >
      <g filter="url(#filter0_ii_8158_25928)">
        <path
          d="M0.920032 0.873781C0.629032 1.18178 0.457031 1.65978 0.457031 2.27878V24.3948C0.457031 25.0148 0.629032 25.4918 0.920032 25.7998L0.994031 25.8718L13.383 13.4828V13.3368V13.1908L0.994031 0.800781L0.920032 0.873781Z"
          fill="url(#paint0_linear_8158_25928)"
        />
        <path
          d="M17.5118 17.6135L13.3828 13.4825V13.3365V13.1905L17.5128 9.06055L17.6058 9.11355L22.4988 11.8935C23.8958 12.6875 23.8958 13.9865 22.4988 14.7815L17.6058 17.5615L17.5118 17.6135Z"
          fill="url(#paint1_linear_8158_25928)"
        />
        <g filter="url(#filter1_i_8158_25928)">
          <path
            d="M17.6079 17.5599L13.3839 13.3359L0.921876 25.7989C1.38188 26.2869 2.14287 26.3469 2.99987 25.8609L17.6079 17.5599Z"
            fill="url(#paint2_linear_8158_25928)"
          />
        </g>
        <path
          d="M17.6079 9.11264L2.99987 0.812638C2.14287 0.325638 1.38188 0.386638 0.921876 0.874638L13.3849 13.3376L17.6079 9.11264Z"
          fill="url(#paint3_linear_8158_25928)"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_8158_25928"
          x="0.457031"
          y="0.476562"
          width="23.0898"
          height="25.7207"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8158_25928"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_8158_25928"
            result="effect2_innerShadow_8158_25928"
          />
        </filter>
        <filter
          id="filter1_i_8158_25928"
          x="0.921875"
          y="13.3359"
          width="16.6875"
          height="12.8613"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8158_25928"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8158_25928"
          x1="12.2848"
          y1="2.04478"
          x2="-4.49717"
          y2="18.8268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.0066" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8158_25928"
          x1="24.3173"
          y1="13.3365"
          x2="0.121413"
          y2="13.3365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8158_25928"
          x1="15.314"
          y1="15.6298"
          x2="-7.44452"
          y2="38.3883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8158_25928"
          x1="-2.21673"
          y1="-6.48756"
          x2="7.94558"
          y2="3.67474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  )
}
