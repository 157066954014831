import React from "react"

export const IconDetailOnButton = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_8063_24514)">
        <circle
          cx="10.0013"
          cy="10.0001"
          r="8.33333"
          stroke="#FAF8FF"
          strokeWidth="1.5"
        />
        <path
          d="M10.832 12.5L13.332 10M13.332 10L10.832 7.5M13.332 10L6.66536 10"
          stroke="#FAF8FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8063_24514">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
