import { IconAppStore } from "@/app/components/icon-tsx/IconAppStore"
import { IconGGPlay } from "@/app/components/icon-tsx/IconGGPlay"
import { IconStar } from "@/app/components/icon-tsx/IconStar"
import { Button } from "@/app/components/ui/button"
import { MobileContext } from "@/app/context"
import { getCdnImage } from "@/app/helpers/function"
import { useWindowSize } from "@/app/hooks"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IGame } from "@/app/interfaces"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useRouter } from "next/router"
import React, { FC, useContext } from "react"

interface IGameAll {
  games: IGame[]
}

export const GameAll: FC<IGameAll> = ({ games }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isMobile, mobileOS } = useContext(MobileContext)
  const [width] = useWindowSize()
  const isTablet = width < 1024
  const GAEventsTracker = useGAEventsTracker("Game")

  const gotoGameDetail = (id: string) => {
    router.push(`/game/${id}`)
  }

  const renderCardGame = (game_item: IGame, i: number) => {
    return (
      <div
        className="w-full h-full md:relative md:rounded-[18px] md:overflow-hidden md:aspect-[16/9] content-none hover:brightness-110 cursor-pointer trending_card"
        key={i}
        onClick={() => {
          GAEventsTracker("View_Game", game_item?.game_name)
          gotoGameDetail(game_item?.slug_name)
        }}
      >
        <div className="md:absolute w-full aspect-[3/4] md:aspect-[16/9]">
          <Image
            width={410}
            height={280}
            src={
              isTablet
                ? getCdnImage(game_item.cover?.cover_md)
                : getCdnImage(game_item.cover?.cover_lg)
            }
            alt={game_item?.game_name}
            title={game_item?.game_name}
            className="w-full h-full object-cover rounded-[18px] aspect-[3/4] md:aspect-[16/9]"
          />
        </div>
        <div className="absolute z-0 hidden w-full h-full animate_buttons bg-gradient-to-b from-black/0 to-black md:block"></div>
        <div
          className="animate_buttons absolute top-[24px] left-[24px] right-[24px] items-center gap-[8px] rounded-[8px] w-fit px-[8px] py-[4px] hidden md:flex"
          style={{
            background: "rgba(255, 255, 255, 0.10)",
            backdropFilter: "blur(2px)"
          }}
        >
          {(!isMobile || mobileOS == "iOS") && (
            <div
              className="w-[28px] h-[28px] flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault()
                window.open(game_item.install_link.ios, "_blank")
              }}
            >
              <IconAppStore />
            </div>
          )}

          {!isMobile && <div className="h-[24px] bg-white w-[1px]"></div>}
          {(!isMobile || mobileOS !== "iOS") && (
            <div
              className="w-[28px] h-[28px] flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault()
                window.open(game_item.install_link.android, "_blank")
              }}
            >
              <IconGGPlay />
            </div>
          )}
        </div>
        <div
          className={`${
            width >= 768 && "animate_buttons"
          } md:absolute md:bottom-[24px] md:left-[24px] md:right-[24px] Nunito-500 text-[12px] md:text-main-white gap-[14px] flex mt-[8px] md:mt-0`}
        >
          <Image
            src={getCdnImage(game_item.icon)}
            alt={game_item.game_name}
            width={60}
            height={60}
            className="w-10 h-10 rounded-[15px] hidden md:block"
          ></Image>
          <div className="pl-[10px] md:pl-0">
            <div className="Nunito-700 text-[16px]">{game_item.game_name}</div>
            <div className="flex items-center">
              <IconStar className="w-[12px] h-[12px] xs:h-[7px] xs:h-[7px]" />
              <p className="text-[10px] md:text-[12px] ml-[6px]">
                {game_item?.rating?.stars}
              </p>
              <div className="bg-main-white w-[4px] h-[4px] rounded-[4px] mx-[7px]"></div>
              <p className="text-[10px] md:text-[12px]">
                {game_item?.category}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="md:mt-[40px] flex flex-col">
      <div className="md:flex justify-between items-center mb-[16px] hidden">
        <h2 className="text-[24px] Nunito-700">{t("common.all")}</h2>
        <p
          className="text-[16px] Nunito-500 text-main-01 cursor-pointer"
          onClick={() => {
            GAEventsTracker("All_games")
            router.push("game/all-game")
          }}
        >
          {t("common.view_more")}
        </p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-y-[16px] gap-x-[24px] xs:gap-x-[16px]">
        {games?.slice(0, 6)?.map((game_item: IGame, index: number) => {
          return renderCardGame(game_item, index)
        })}
      </div>
      <Button
        variant="primary"
        className="mt-[16px] px-[24px] py-[4px] mx-auto md:hidden h-[27px] w-[116px]"
        onClick={() => {
          GAEventsTracker("All_games")
          router.push("game/all-game")
        }}
      >
        {t("common.view_all")}
      </Button>
    </div>
  )
}
