import { IconDetailOnButton } from "@/app/components/icon-tsx/IconDetailOnButton"
import { Button } from "@/app/components/ui/button"
import { isClient } from "@/app/configs"
import { getCdnImage, removeInlineStylesAndClasses } from "@/app/helpers/function"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IGame } from "@/app/interfaces"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useRouter } from "next/router"
import { FC, useEffect, useRef, useState } from "react"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

interface IGameBannerProps {
  listBanner: any
  listGame: IGame[]
}

const LIMIT_BANNER = 4
const SWITCH_SLIDE_TIME = 5000

export const GameBanner: FC<IGameBannerProps> = ({
  listBanner: list,
  listGame
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [currentBanner, setCurrentBanner] = useState(0)
  const GAEventsTracker = useGAEventsTracker("Game")
  const img1Ref = useRef<any>(null)
  const [maxHeight, setMaxHeight] = useState(0)
  const token = isClient ? window.localStorage.getItem("token") : null

  const listBanner = list
    .sort((a: any, b: any) => b.start_time - a.start_time)
    .slice(0, 4)

  useEffect(() => {
    const updateMaxHeight = () => {
      if (img1Ref.current) {
        const img1Height = img1Ref.current.clientHeight
        setMaxHeight(img1Height)
      }
    }

    updateMaxHeight()

    window.addEventListener("resize", updateMaxHeight)
    return () => window.removeEventListener("resize", updateMaxHeight)
  }, [img1Ref, currentBanner])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (currentBanner === LIMIT_BANNER - 1) {
        setCurrentBanner(0)
      } else setCurrentBanner(currentBanner + 1)
    }, SWITCH_SLIDE_TIME)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  const renderBanner = (index: number, banner: any, game: IGame, ref: any) => {
    return (
      <div
        ref={ref}
        key={index}
        onClick={() => {
          if (index !== currentBanner) {
            setCurrentBanner(index)
          } else {
            GAEventsTracker("Click_Top_Banner", banner?.click_url)
            const link =
              banner?.click_url?.includes(
                process.env.NEXT_PUBLIC_EVENT_DOMAIN || ""
              ) && token
                ? `${banner?.click_url}?token=${token}`
                : banner?.click_url
            router.push(link)
          }
        }}
        className={`${
          currentBanner === index
            ? "h-full w-full md:w-[74.53125%] aspect-[16/9]"
            : "w-full cursor-pointer h-full "
        }  relative rounded-[14px] lg:rounded-[16px] overflow-hidden text-main-white cursor-pointer active:opacity-80`}
      >
        <Image
          alt={game?.game_name}
          width={954}
          height={536}
          src={getCdnImage(banner?.banner)}
          className="rounded-[14px] lg:rounded-[16px] object-cover w-full h-full duration-100 ease-linear"
          style={{
            boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.4)"
          }}
        />
        {game?.game_type && game?.game_type?.length > 0 && (
          <div
            className={`absolute backdrop-blur-[2px] top-[16px] md:top-[23px] lg:top-[40px] left-[16px] md:left-[23px] lg:left-[40px] text-[14px] Nunito-500 text-main-white leading-[19px] px-[12px] py-[6px] rounded-[12px] ${
              game?.game_type.includes("editor_choice")
                ? "bg-label-03"
                : game?.game_type.includes("hot_releases")
                ? "bg-label-02"
                : game?.game_type.includes("upcoming")
                ? "bg-label-01"
                : ""
            } ${currentBanner !== index && "hidden"}`}
          >
            {game?.game_type.includes("editor_choice")
              ? "#Editor Choice"
              : game?.game_type.includes("hot_releases")
              ? "#Hot Release"
              : game?.game_type.includes("upcoming")
              ? "#Upcoming"
              : ""}
          </div>
        )}
        <div
          className={`lg:h-[80%] w-full absolute bottom-0 left-0 right-0 p-[16px] md:p-[23px] lg:p-[40px] pt-0 justify-end flex flex-col ${
            currentBanner !== index && "hidden"
          }`}
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 0, 0, 0.50) 43%)"
          }}
        >
          <h1
            className="text-main-white text-[16px] md:text-[20px] lg:text-[32px] Nunito-700 cursor-pointer pb-[8px]"
            onClick={() => {
              GAEventsTracker("Click_Top_Banner", banner?.click_url)
              router.push(banner?.click_url)
            }}
          >
            {game?.game_name}
          </h1>
          {/* <div className="py-[8px] pt-0 md:pt-[8px] flex items-center">
            <IconStar className="w-[12px] h-[12px] xs:h-[7px] xs:h-[7px]" />
            <p className="text-[10px] md:text-[12px] ml-[6px]">
              {game?.rating?.stars}
            </p>
            <div className="bg-main-white w-[4px] h-[4px] rounded-[4px] mx-[7px]"></div>
            <p className="text-[10px] md:text-[12px]">{game?.category}</p>
          </div> */}
          <div
            className="text-main-white max-w-[80%] hidden md:line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: removeInlineStylesAndClasses(game?.description) || ""
            }}
          ></div>
          <div className="flex xs:gap-[8px] xs:flex-wrap gap-[20px] lg:gap-[48px] md:mt-[12px] items-center">
            <Button
              variant={"main"}
              onClick={() => {
                GAEventsTracker("Click_Top_Banner", banner?.click_url)
                router.push(banner?.click_url)
              }}
              className="flex items-center gap-[8px] sm:gap-[12px] items-center px-[12px] sm:px-[24px] xs:text-[12px] xs:py-[6px] xs:h-[28px] h-[48px] !xs:rounded-[12px] !rounded-[16px] cursor-pointer"
            >
              {t("reward.detail")}{" "}
              <IconDetailOnButton className="xs:w-[14px] xs:h-[14px] w-[24px] h-[24px]" />
            </Button>

            {/* <div className="flex xs:gap-[3px] gap-[8px] items-center">
              <div
                className="bg-black/10 rounded-[8px] p-[4px] xs:p-[2px]"
                style={{
                  backdropFilter: "blur(4px)"
                }}
              >
                <IconStar className="w-[24px] h-[24px] xs:w-[20px] xs:h-[20px]" />
              </div>
              <div>
                <p className="text-[12px] Nunito-700 leading-[12px] pb-1">
                  {game?.rating?.stars}
                </p>
                <p className="text-[10px] xs:text-[7px] whitespace-nowrap ">
                  {t("common.rating")}
                </p>
              </div>
            </div>

            <div className="flex gap-[8px] items-center">
              <div
                className="bg-black/10 rounded-[8px] p-[4px] xs:p-[2px]"
                style={{
                  backdropFilter: "blur(4px)"
                }}
              >
                <IconDownload className="w-[24px] h-[24px] xs:w-[20px] xs:h-[20px]" />
              </div>
              <div>
                <p className="text-[12px] Nunito-700 leading-[12px] pb-1">
                  {formatNumber(game?.total_download || 0)}
                </p>
                <p className="text-[10px] xs:text-[7px] whitespace-nowrap">
                  {t("common.downloaded")}
                </p>
              </div>
            </div>

            <div className="flex gap-[8px] items-center">
              <div
                className="bg-black/10 rounded-[8px] p-[4px] xs:p-[2px]"
                style={{
                  backdropFilter: "blur(4px)"
                }}
              >
                <IconUser
                  className="w-[24px] h-[24px] xs:w-[20px] xs:h-[20px]"
                  fill="#7673E5"
                />
              </div>
              <div>
                <p className="text-[12px] Nunito-700 leading-[12px] pb-1">
                  {formatNumber(game?.total_download || 0)}
                </p>
                <p className="text-[10px] xs:text-[7px] whitespace-nowrap">
                  {t("common.players")}
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  const current = listBanner?.[currentBanner]
  const currentGame = listGame?.find(
    (game) => game.id === current.game_id
  ) as IGame

  return (
    <div
      className="mt-[40px] xs:mt-0 game-banner"
      style={{
        zIndex: "2"
      }}
    >
      <div
        className="hidden md:flex gap-[14px] lg:gap-[24px] w-full max-h-[536px] text-main-white relative h-auto"
        style={{
          zIndex: "2",
          maxHeight: `${maxHeight === 0 ? 536 : maxHeight}px`
        }}
      >
        {renderBanner(currentBanner, current, currentGame, img1Ref)}
        <div className="hidden md:flex gap-[14px] justify-between lg:gap-[24px] flex-col flex-1 h-auto">
          {listBanner?.length &&
            listBanner?.map((banner: any, index: number) => {
              const game = listGame?.find(
                (game) => game.id === banner.game_id
              ) as IGame

              return index === currentBanner
                ? null
                : renderBanner(index, banner, game, null)
            })}
        </div>
      </div>

      <div
        className="md:hidden relative"
        style={{
          zIndex: "2"
        }}
      >
        <Swiper
          modules={[Autoplay, Pagination]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 50000,
            disableOnInteraction: true
          }}
          className="banner-swiper"
        >
          {listBanner?.length
            ? listBanner.map((banner: any, i: number) => {
                const game = listGame?.find(
                  (game) => game.id === banner.game_id
                ) as IGame
                return (
                  <SwiperSlide key={i}>
                    {renderBanner(currentBanner, banner, game, null)}
                  </SwiperSlide>
                )
              })
            : null}
        </Swiper>
      </div>
    </div>
  )
}
