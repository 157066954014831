import { GameContainer } from "@/app/containers/game"
import { GetServerSideProps, GetServerSidePropsContext } from "next"
import { i18n, withTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

const Home = () => {
  return (
    <>
      <GameContainer />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  let metaData: any = {
    title: i18n?.t("seo.home_title"),
    desc: i18n?.t("seo.home_description"),
    keyword: i18n?.t("seo.home_keywords"),
    canonical: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    image: `${process.env.NEXT_PUBLIC_HOST_URL}/img/playee_portal.png`
  }
  return {
    props: {
      // use that for fix error serializing
      metaPage: JSON.parse(JSON.stringify(metaData)) || null,
      ...(await serverSideTranslations(context?.locale ?? "vi-VN", ["common"]))
    }
  }
}

export default withTranslation("common")(Home)
