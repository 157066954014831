export const IconStar = ({
  fill,
  className,
  onClick
}: {
  fill?: string
  className?: string
  onClick?: () => void
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M4.85378 0.908283C5.32318 -0.0805918 6.67943 -0.0805937 7.14883 0.908281L7.96007 2.61731C8.14647 3.01 8.50678 3.28217 8.92358 3.34514L10.7376 3.6192C11.7872 3.77777 12.2063 5.11886 11.4468 5.88859L10.1342 7.21889C9.83257 7.52455 9.69494 7.96494 9.76614 8.39654L10.076 10.2749C10.2553 11.3618 9.15807 12.1907 8.21927 11.6775L6.59678 10.7906C6.22399 10.5869 5.77862 10.5869 5.40582 10.7906L3.78333 11.6775C2.84453 12.1907 1.7473 11.3618 1.92659 10.2749L2.23646 8.39654C2.30766 7.96494 2.17003 7.52455 1.86843 7.21889L0.555813 5.88859C-0.203693 5.11886 0.21541 3.77777 1.26502 3.6192L3.07902 3.34514C3.49582 3.28217 3.85613 3.01 4.04253 2.61731L4.85378 0.908283Z"
        fill={fill ? fill : "#7673E6"}
      />
    </svg>
  )
}
