import { IconArrowLeft } from "@/app/components/icon-tsx/IconArrowLeft"
import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import { MobileContext } from "@/app/context"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useRouter } from "next/router"
import React, { useContext, useMemo, useRef } from "react"
import { IconAppStore } from "@/app/components/icon-tsx/IconAppStore"
import { IconGGPlay } from "@/app/components/icon-tsx/IconGGPlay"
import { IconStar } from "@/app/components/icon-tsx/IconStar"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { getCdnImage } from "@/app/helpers/function"

SwiperCore.use([Pagination, Navigation])

interface IEditorChoice {
  games: any
}
export const EditorChoice: React.FC<IEditorChoice> = ({ games }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { isMobile, mobileOS } = useContext(MobileContext)
  const navigationPrevRef = useRef<HTMLButtonElement>(null)
  const navigationNextRef = useRef<HTMLButtonElement>(null)
  const GAEventsTracker = useGAEventsTracker("Game")

  const gotoGameDetail = (id: string) => {
    router.push(`/game/${id}`)
  }

  const renderCardGame = (game_item: any, i: number) => {
    return (
      <div
        className="w-full lg:w-full relative rounded-[16px] h-[278px] lg:h-[315px] overflow-hidden content-none hover:brightness-110 cursor-pointer trending_card"
        key={i}
        style={{
          boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.04)"
        }}
        onClick={() => {
          GAEventsTracker("View_Game", game_item?.game_name)
          gotoGameDetail(game_item?.slug_name)
        }}
      >
        <div className="absolute w-full h-full">
          <Image
            width={237}
            height={320}
            src={getCdnImage(game_item.cover?.cover_md)}
            alt={game_item?.game_name}
            title={game_item?.game_name}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="absolute z-0 w-full h-full animate_buttons bg-gradient-to-b from-black/0 to-black"></div>
        <div
          className="animate_buttons absolute top-[16px] left-[16px] right-[16px] flex items-center gap-[8px] rounded-[8px] w-fit px-[8px] py-[4px]"
          style={{
            background: "rgba(255, 255, 255, 0.10)",
            backdropFilter: "blur(2px)"
          }}
        >
          {(!isMobile || mobileOS == "iOS") && (
            <div
              className="w-[28px] h-[28px] flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault()
                window.open(game_item.install_link.ios, "_blank")
              }}
            >
              <IconAppStore />
            </div>
          )}

          {!isMobile && <div className="h-[24px] bg-white w-[1px]"></div>}
          {(!isMobile || mobileOS !== "iOS") && (
            <div
              className="w-[28px] h-[28px] flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault()
                window.open(game_item.install_link.android, "_blank")
              }}
            >
              <IconGGPlay />
            </div>
          )}
        </div>
        <div className="animate_buttons absolute bottom-[16px] left-[16px] right-[16px] Nunito-500 text-[12px] text-[#ffffff] flex gap-[14px]">
          <Image
            src={getCdnImage(game_item.icon)}
            alt={game_item.game_name}
            width={60}
            height={60}
            className="w-10 h-10 rounded-[15px]"
          ></Image>
          <div>
            <div className="Nunito-700 text-[16px]">{game_item.game_name}</div>
            <div className="flex items-center">
              <IconStar className="w-[12px] h-[12px] xs:h-[7px] xs:h-[7px]" />
              <p className="text-[10px] md:text-[12px] ml-[6px]">
                {game_item?.rating?.stars}
              </p>
              <div className="bg-main-white w-[4px] h-[4px] rounded-[4px] mx-[7px]"></div>
              <p className="text-[10px] md:text-[12px]">
                {game_item?.category}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderListGame = useMemo(() => {
    return (
      <div className="mt-2 overflow-auto">
        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current && navigationPrevRef.current,
            nextEl: navigationNextRef.current && navigationNextRef.current
          }}
          onInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
            swiper.navigation.enable()
            swiper.navigation.update()
          }}
          slidesPerView={"auto"}
          breakpoints={{
            "991": {
              spaceBetween: 24
            }
          }}
          spaceBetween={11}
          className="w-full "
        >
          {games?.map((game: any, index: number) => {
            return (
              <SwiperSlide
                className="w-fit max-w-[210px] lg:max-w-[236px] xs:max-w-[116px] h-full flex"
                key={index}
              >
                {renderCardGame(game, index)}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    )
  }, [games, isMobile])

  return (
    <div
      className="pt-[40px] relative"
      style={{
        zIndex: "2"
      }}
    >
      {games?.length > 0 ? (
        <>
          <div className="text-[24px] mb-[16px] Nunito-700 flex items-center justify-between">
            <h2 className="text-[24px] Nunito-700">Editor’s Choice</h2>
            <div className="flex items-center gap-[12px] ml-auto">
              <div className="flex items-center gap-[8px] ml-auto">
                <button
                  className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer xs:w-[24px] xs:h-[24px]`}
                  ref={navigationPrevRef}
                >
                  <IconArrowLeft className="xs:w-[16px] xs:h-[16px]" />
                </button>
                <button
                  className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
                  ref={navigationNextRef}
                >
                  <IconArrowRight className="xs:w-[16px] xs:h-[16px]" />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {renderListGame}
    </div>
  )
}
