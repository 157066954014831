import { IconArrowLeft } from "@/app/components/icon-tsx/IconArrowLeft"
import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import { MobileContext } from "@/app/context"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/app/components/ui/carousel"
import { IconStar } from "@/app/components/icon-tsx/IconStar"
import { IconAppStore } from "@/app/components/icon-tsx/IconAppStore"
import { IconGGPlay } from "@/app/components/icon-tsx/IconGGPlay"
import { IGame } from "@/app/interfaces"
import { getEmptyListByNumber, getNumberList } from "@/app/utils"
import { useWindowSize } from "@/app/hooks"
import { DataEmpty } from "@/app/components/data-empty"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { getCdnImage } from "@/app/helpers/function"

interface IGameRanking {
  games: any
}

interface IType {
  type: string
  name: string
}

export const GameRanking: React.FC<IGameRanking> = ({ games }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { isMobile, mobileOS } = useContext(MobileContext)
  const [selectedType, setselectedType] = useState<IType>({
    type: "",
    name: t("common.all")
  })
  const refPrev = useRef<any>()
  const refNext = useRef<any>()
  const [width] = useWindowSize()
  const isNotTablet = width < 768
  const NUMBER_GAME_BY_COL = 3
  const GAEventsTracker = useGAEventsTracker("Game")

  const listRankingType = [
    {
      type: "",
      name: t("common.all")
    },
    {
      type: "trending",
      name: t("common.trendings")
    },
    {
      type: "popular",
      name: t("common.populars")
    },

    {
      type: "new_releases",
      name: t("common.new_releases")
    },
    {
      type: "top_gross",
      name: t("common.top_gross")
    },
    {
      type: "most_played",
      name: t("common.most_played")
    }
  ]

  let filteredGame = games
  if (selectedType.type != "") {
    filteredGame = games.filter((game: IGame) =>
      game?.game_type?.includes(selectedType?.type)
    )
  }
  if (selectedType.type === "") {
    filteredGame = games
  }

  const gotoGameDetail = (id: string) => {
    router.push(`/game/${id}`)
  }

  const renderCardGame = (game_item: IGame, index: number) => {
    return (
      <div
        className="flex items-center gap-[26px] mt-[16px] md:mt-0"
        key={game_item?.id}
        onClick={() => {
          GAEventsTracker("View_Game", game_item?.game_name)
          gotoGameDetail(game_item?.slug_name)
        }}
      >
        <p className="text-[16px] text-main-01 w-[12px]">{index + 1}</p>
        <div className="flex gap-[14px] items-center cursor-pointer">
          <Image
            src={getCdnImage(game_item.icon)}
            alt={game_item.game_name}
            title={game_item.game_name}
            width={56}
            height={56}
            className="rounded-[16px]"
          ></Image>
          <div>
            <h3 className="Nunito-700 text-[16px]">{game_item.game_name}</h3>
            <div className="flex items-center">
              <IconStar className="w-[12px] h-[12px] xs:h-[7px] xs:h-[7px]" />
              <p className="text-[10px] md:text-[12px] ml-[6px] text-neutral dark:text-neutral-01">
                {game_item?.rating?.stars}
              </p>
              <div className="bg-neutral w-[4px] h-[4px] rounded-[4px] mx-[7px] dark:bg-neutral-01"></div>
              <p
                className="text-[10px] md:text-[12px] text-neutral dark:text-neutral-01"
                onClick={(e) => {
                  e.stopPropagation()
                  router.push({
                    pathname: "game/all-game",
                    query: {
                      search: "",
                      category: JSON.stringify([game_item?.category])
                    }
                  })
                }}
              >
                {game_item?.category}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderListGame = useMemo(() => {
    return (
      <div className="overflow-auto">
        {isNotTablet ? (
          filteredGame.map((game_item: any, i: number) => {
            return renderCardGame(game_item, i)
          })
        ) : (
          <CarouselContent className="-ml-[24px]">
            {getEmptyListByNumber(
              getNumberList(filteredGame?.length, NUMBER_GAME_BY_COL)
            )?.map((index: number) => {
              return (
                <CarouselItem
                  key={index}
                  className="md:basis-1/2 lg:basis-1/3 pl-[24px] flex gap-[48px] flex-col mt-[16px]"
                >
                  {renderCardGame(
                    filteredGame?.[index * NUMBER_GAME_BY_COL],
                    index * NUMBER_GAME_BY_COL
                  )}
                  {filteredGame?.[index * NUMBER_GAME_BY_COL + 1] &&
                    renderCardGame(
                      filteredGame?.[index * NUMBER_GAME_BY_COL + 1],
                      index * NUMBER_GAME_BY_COL + 1
                    )}
                  {filteredGame?.[index * NUMBER_GAME_BY_COL + 2] &&
                    renderCardGame(
                      filteredGame?.[index * NUMBER_GAME_BY_COL + 2],
                      index * NUMBER_GAME_BY_COL + 2
                    )}
                </CarouselItem>
              )
            })}
          </CarouselContent>
        )}

        {filteredGame?.length === 0 && (
          <div className="flex items-center justify-center min-h-[300px] xs:min-h-[200px]">
            <DataEmpty />
          </div>
        )}
      </div>
    )
  }, [filteredGame, isMobile, isNotTablet])

  return (
    <div
      className="md:pt-[40px] relative hidden-scroll-bar"
      style={{
        zIndex: "2"
      }}
    >
      {listRankingType.length > 0 ? (
        <>
          <div className="text-[24px] mb-[16px] Nunito-700 hidden md:flex items-center justify-center">
            <h2 className="Nunito-700 text-[24px] m-0 p-0">
              {t("common.top_chart")}
            </h2>
            <div className="md:flex items-center gap-[8px] ml-auto hidden">
              <div
                className={`p-[2px] disable rounded-[10px] border border-main-01 cursor-pointer`}
                onClick={() => {
                  refPrev?.current.click()
                }}
              >
                <IconArrowLeft />
              </div>
              <div
                className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
                onClick={() => {
                  refNext?.current.click()
                }}
              >
                <IconArrowRight />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <Carousel
        opts={{
          align: "start"
        }}
      >
        {renderListGame}
        <CarouselNext ref={refNext} className="hidden" />
        <CarouselPrevious ref={refPrev} className="hidden" />
      </Carousel>
    </div>
  )
}
