import { IGame } from "@/app/interfaces"
import React, { FC } from "react"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from "@/app/components/ui/tabs"
import { useTranslation } from "next-i18next"
import { GameAll } from "./GameAll"
import { GameRanking } from "./GameRanking"

interface IGameTabMobile {
  games: IGame[]
}

const GAME_TAB = {
  GAME_ALL: "game_all",
  RANKING: "ranking"
}

export const GameTabMobile: FC<IGameTabMobile> = ({ games }) => {
  const { t } = useTranslation()

  return (
    <Tabs
      defaultValue={GAME_TAB.GAME_ALL}
      className="w-full mt-[12px] md:mt-[24px]"
      style={{
        zIndex: "2"
      }}
    >
      <TabsList className="grid w-full grid-cols-2 mb-[16px] rounded-none px-[8px] ">
        <TabsTrigger value={GAME_TAB.GAME_ALL}>{t("common.all")}</TabsTrigger>
        <TabsTrigger value={GAME_TAB.RANKING}>
          {t("common.top_chart")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value={GAME_TAB.GAME_ALL}>
        <GameAll games={games} />
      </TabsContent>
      <TabsContent value={GAME_TAB.RANKING}>
        <GameRanking games={games} />
      </TabsContent>
    </Tabs>
  )
}
