import { ICategory, IGame } from "@/app/interfaces"
import React, { FC, useEffect, useState } from "react"
import { GameBanner } from "./GameBanner"
import { GameServices } from "@/app/services/services/GameServices"
import { GameCategory } from "./GameCategory"
import { GameAll } from "./GameAll"
import { GameRanking } from "./GameRanking"
import { GameTabMobile } from "./GameTabMobile"
import { EditorChoice } from "./EditorChoice"
import {
  useQueryGetListGame,
  useQueryGetListGameBanner
} from "@/app/services/query-fn/game"

interface IGameProps {}

export const GameContainer: FC<IGameProps> = ({}) => {
  const [allGames, setAllGames] = useState<IGame[]>()
  const [allCategory, setAllCategory] = useState<ICategory[]>()
  const { data: listEditorChoice } = useQueryGetListGame({
    category: "",
    type: "editor_choice",
    limit: 10,
    page: 1
  })
  const { data: listGameBanner } = useQueryGetListGameBanner({
    type: "list_game"
  })

  const getDataGame = async () => {
    const categories = await GameServices.getAllCategories()
      .then((resp) => {
        return resp.data.data
      })
      .catch(() => [])
    const games = await GameServices.getAllGames({
      category: "",
      type: "",
      limit: 100,
      page: 1
    })
      .then((resp) => resp.data.data)
      .catch(() => [])

    setAllGames(games)
    setAllCategory(categories)
  }

  useEffect(() => {
    getDataGame()
  }, [])

  return (
    <div>
      {listGameBanner && allGames && (
        <GameBanner listBanner={listGameBanner} listGame={allGames} />
      )}
      {allGames && allCategory && (
        <>
          {/* desktop view */}
          <div
            className="hidden md:block"
            style={{
              zIndex: "2"
            }}
          >
            <EditorChoice games={listEditorChoice} />
            {/* <GameCategory games={allGames} categories={allCategory} /> */}
            <GameRanking games={allGames} />
            <GameAll
              games={allGames.filter(
                (game) =>
                  !listEditorChoice?.find((item) => item?.id === game.id) &&
                  !listGameBanner?.find((item) => item?.game_id === game.id)
              )}
            />
          </div>

          {/* mobile view */}
          <div
            className="relative md:hidden"
            style={{
              zIndex: "2"
            }}
          >
            <GameTabMobile games={allGames} />
          </div>
        </>
      )}
    </div>
  )
}
